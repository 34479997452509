import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../svg/HeroImage';
import SvgCharts from '../svg/SvgCharts';

export default () => (
  <Layout>
    <section id="features" className="lg:pb-40 lg:pt-48">
      <div className="container mx-auto">
        <h2 className="text-3xl font-semibold">Terms & Conditions</h2>
        <p>Terms and Conditions
WEBSITE TERMS OF USE</p>

<p>Last Modified: December 10, 2018</p>

<p>Acceptance of the Terms of Use</p>

<p>These terms of use are entered into by and between You and Hacking Beauty LLC (“Company”, “we” or “us”). The following terms and conditions, together with any documents they expressly incorporate by reference (collectively, these “Terms of Use”), govern your access to and use of (a) http://fullstackdapps.com (b) https://professionalreactapp.com, and/or (c) https://reactdapps.com, including any content, functionality and services offered on or through such websites, whether as a guest or a registered user. As used herein, “Website” shall refer to each of the aforementioned websites, or any combination of them. Please read the Terms of Use carefully before you start to use the Website. By using the Website, you accept and agree to be bound and abide by these Terms of Use, our privacy policy found at http://hackingbeauty.com/privacy-policy (the “Privacy Policy”), and our earnings disclaimer found at http://hackingbeauty.com/disclaimer/ (the “Earnings Disclaimer) all of which are incorporated herein by reference. If you do not want to agree to or accept these Terms of Use, the Privacy Policy, or the Earnings Disclaimer, you must not access or use the Website.</p>

<p>The Website is only offered and available to users who are 18 or older and fully able and competent to enter into the terms, conditions, obligations, affirmations, representations, and warranties set forth in these Terms of Use, and to abide by and comply with these Terms of Use. If you do not meet all of the foregoing requirements, you must not access or use any Website.</p>

<p>Changes to the Terms of Use</p>

<p>We may revise and update these Terms of Use from time to time in our sole discretion. All changes are effective immediately when we post them, and apply to all access to and use of the Website thereafter. However, any changes to the dispute resolution provisions set forth in Governing Law and Jurisdiction will not apply to any disputes for which the parties have actual notice on or prior to the date the change is posted on the Website. Your continued use of the Website following the posting of revised Terms of Use means that you accept and agree to the changes. We encourage and expect you to consult the Terms of Use each time you access a Website so you are aware of any changes, as they are binding on you.</p>

<p>Accessing the Website and Account Security</p>

<p>We reserve the right to withdraw or amend the Website, and any service or material we provide on the Website, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Website is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Website, or the entire Website, to users, including registered users. You are responsible for (a) making all arrangements necessary for you to have access to the Website and (b) ensuring that all persons who access the Website through your internet connection are aware of these Terms of Use and comply with them.</p>

<p>To access the Website or some of the resources it offers, you may be asked to provide certain registration details or other information. It is a condition of your use of the Website that all the information you provide on the Website is correct, current and complete. You agree that all information you provide to register with this Website or otherwise, including but not limited to through the use of any interactive features on the Website, is governed by our Privacy Policy, and you consent to all actions we take with respect to your information consistent with our Privacy Policy.</p>

<p>If you choose, or are provided with, a user name, password or any other piece of information as part of our security procedures, you must treat such information as confidential, and you must not disclose it to any other person or entity. You also acknowledge that your account is personal to you and agree not to provide any other person with access to this Website or portions of it using your user name, password or other security information. You agree to notify us immediately of any unauthorized access to or use of your user name or password or any other breach of security. We have the right to disable any user name, password or other identifier, whether chosen by you or provided by us, at any time in our sole discretion for any or no reason, including if, in our opinion, you have violated any provision of these Terms of Use.</p>

<p>Intellectual Property Rights</p>

<p>The Website and its entire contents, features and functionality (including but not limited to all information, software, text, displays, images, video and audio, and the design, selection and arrangement thereof), are owned by the Company, its licensors or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws. Violation of this Section is strictly prohibited without the express, written permission of Company. For information on requesting such permission, contact us at support@hackingbeauty.com.</p>

<p>These Terms of Use permit you to use the Website for your personal, non-commercial use only. You must not sell, license, rent, copy, reproduce, transmit, distribute, modify, create derivative works from, publicly display, publicly perform, republish, adapt, edit, download, store or transmit any of the material on our Website, with the following exceptions: (1) your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials; (2) you may store files that are automatically cached by your Web browser for display enhancement purposes; (3) you may print or download one copy of a reasonable number of pages of the Website for your own personal, non-commercial use and not for further reproduction, publication or distribution; (4) if we provide desktop, mobile or other applications for download, you may download a single copy to your computer or mobile device solely for your own personal, non-commercial use, provided you agree to be bound by our end user license agreement for such applications; and (5) if we provide social media features with certain content, you may take such actions as are enabled by such features.</p>

<p>You must not (1) modify copies of any materials from the Website; (2) use any illustrations, photographs, video or audio sequences or any graphics from the Website separately from the accompanying text; (3) delete or alter any copyright, trademark or other proprietary rights notices from copies of materials from the Website. You must not access or use for any commercial purposes any part of the Website or any services or materials available through the Website.</p>

<p>If you print, copy, modify, download or otherwise use or provide any other person with access to any part of the Website in breach of the Terms of Use, your right to use the Website will cease immediately and you must, at our option, return or destroy any copies of the materials you have made. No right, title or interest in or to the Website or any content on the Website is transferred to you, and all rights not expressly granted are reserved by the Company. Any use of the Website not expressly permitted by these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark and other laws.</p>

<p>Trademarks</p>

<p>The Company name, the terms “Self-Made”, “Self-Made Man” and “List-Grow,” and all related names, logos, product and service names, designs and slogans are trademarks of the Company or its affiliates or licensors. You must not use such marks without the prior written permission of the Company. All other names, logos, product and service names, designs and slogans on this Website are the trademarks of their respective owners.</p>

<p>Prohibited Uses</p>

<p>You may use the Website only for lawful purposes and in accordance with these Terms of Use. You agree not to use the Website (a) in any way that violates any applicable federal, state, local or international law or regulation; (b) for the purpose of exploiting, harming or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information or otherwise; (c) to send, knowingly receive, upload, download, use or re-use any material which does not comply with the Content Standards set out in these Terms of Use; (d) to transmit, or procure the sending of, any advertising or promotional material without our prior written consent, including any “junk mail”, “chain letter” or “spam” or any other similar solicitation; (e) to impersonate or attempt to impersonate the Company, a Company employee, another user or any other person or entity (including, without limitation, by using e-mail addresses or screen names associated with any of the foregoing); or (f) to engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the Website, or which, as determined by us, may harm the Company or users of the Website or expose them to liability.</p>

<p>Additionally, you agree not to (1) use the Website in any manner that could disable, overburden, damage, or impair the site or interfere with any other party’s use of the Website, including their ability to engage in real time activities through the Website; (2) use any robot, spider or other automatic device, process or means to access the Website for any purpose, including monitoring or copying any of the material on the Website; (3) use any manual process to monitor or copy any of the material on the Website or for any other unauthorized purpose without our prior written consent; (4) use any device, software or routine that interferes with the proper working of the Website; (5) introduce any viruses, trojan horses, worms, logic bombs or other material which is malicious or technologically harmful; (6) attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the Website, the server on which the Website is stored, or any server, computer or database connected to the Website; (7) attack the Website via a denial-of-service attack or a distributed denial-of-service attack; or (8) otherwise attempt to interfere with the proper working of the Website.</p>

<p>User Contributions</p>

<p>The Website may contain message boards, chat rooms, personal web pages or profiles, forums, bulletin boards, and other interactive features (collectively, “Interactive Services”) that allow users to post, submit, publish, display or transmit to other users or other persons (hereinafter, “post”) content or materials (collectively, “User Contributions”) on or through the Website. All User Contributions must comply with the Content Standards set out in these Terms of Use.</p>

<p>Any User Contribution you post to the Website will be considered non-confidential and non-proprietary. By providing any User Contribution on the Website, you grant us and our licensees, successors and assigns the right to use, reproduce, modify, perform, display, distribute and otherwise disclose to third parties any such material for any purpose.</p>

<p>Without limiting the foregoing, you agree and understand that other individual members of the membership program available on the Website may see the information that you post on any applicable Website membership area. For this reason, you agree that you shall not post any information on the Website that you do not want others to see. You agree that anything you post in the applicable membership area of the Website is at your own risk, and you further agree to hold Company harmless from any and all damage that could occur to you from any information that you post. For additional details, please see our Privacy Policy.</p>

<p>You represent and warrant that (a) you own or control all rights in and to the User Contributions and have the right to grant the license granted above to us and our licensees, successors and assigns; and (b) all of your User Contributions do and will comply with these Terms of Use. You understand and acknowledge that you are responsible for any User Contributions you submit or contribute, and you, not the Company, have full responsibility for such content, including its legality, reliability, accuracy and appropriateness. We are not responsible, or liable to any third party, for the content or accuracy of any User Contributions posted by you or any other user of the Website.</p>

<p>Monitoring and Enforcement; Termination</p>

<p>We have the right to (a) remove or refuse to post any User Contributions for any or no reason in our sole discretion; (b) take any action with respect to any User Contribution that we deem necessary or appropriate in our sole discretion, including if we believe that such User Contribution violates the Terms of Use, including the Content Standards, infringes any intellectual property right or other right of any person or entity, threatens the personal safety of users of the Website or the public or could create liability for the Company; (c) disclose your identity or other information about you to any third party who claims that material posted by you violates their rights, including their intellectual property rights or their right to privacy; (d) take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the Website; (e) terminate or suspend your access to all or part of the Website for any or no reason, including without limitation, any violation of these Terms of Use.</p>

<p>To the extent you are involved in a membership program on the Website, either you or the Company can cancel your participation in any of our membership programs at any time and for any reason. Except for refunds pursuant to any specified refund periods in any payment terms entered into between you and the Company, no refunds for previous months of membership will be given in the event you or Company terminates your membership in the Website.</p>

<p>Without limiting the foregoing, we have the right to fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone posting any materials on or through the Website. YOU WAIVE AND HOLD HARMLESS THE COMPANY FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY THE COMPANY DURING OR AS A RESULT OF ITS INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER THE COMPANY OR LAW ENFORCEMENT AUTHORITIES.</p>

<p>We cannot and/or do not undertake to review all material before it is posted on the Website, and cannot ensure prompt removal of objectionable material after it has been posted. Accordingly, we assume no liability for any action or inaction regarding transmissions, communications or content provided by any user or third party. We have no liability or responsibility to anyone for performance or nonperformance of the activities described in this section.</p>

<p>Content Standards</p>

<p>These content standards apply to any and all User Contributions and use of Interactive Services. User Contributions must in their entirety comply with all applicable federal, state, local and international laws and regulations.</p>

<p>Without limiting the foregoing, User Contributions must not: (a) contain any material which is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory or otherwise objectionable; (b) promote sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion, nationality, disability, sexual orientation or age; (c) infringe any patent, trademark, trade secret, copyright or other intellectual property or other rights of any other person; (d) violate the legal rights (including the rights of publicity and privacy) of others or contain any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with these Terms of Use and our Privacy Policy; (e) be likely to deceive any person; (f) promote any illegal activity, or advocate, promote or assist any unlawful act; (g) cause annoyance, inconvenience or needless anxiety or be likely to upset, embarrass, alarm or annoy any other person; (h) impersonate any person, or misrepresent your identity or affiliation with any person or organization’ (i) involve commercial activities or sales, such as contests, sweepstakes and other sales promotions, barter or advertising; (j) contain any occult, hateful, or racist material; or (k) give the impression that they emanate from or are endorsed by us or any other person or entity, if this is not the case. To the extent it is questionable whether User Contributions violate the Content Standards, the Company reserves the right to make the final decision regarding whether a violation has occurred and reserves the right to terminate any user’s access to the Website without prior notice for a violation of this provision.</p>

<p>Further requirements regarding the conduct that is expected of all users of the Website may also be found via posts from the Company on the Website, and you agree to comply with such requirements.</p>

<p>Copyright Infringement</p>

<p>Reporting Copyright Infringement. We take claims of copyright infringement seriously. We will respond to notices of alleged copyright infringement that comply with applicable law. If you believe any materials accessible on or from the Website infringe your copyright, you may request removal of those materials (or access to them) from the Website by submitting written notification to our Copyright Agent (designated below). In accordance with the Online Copyright Infringement Liability Limitation Act of the Digital Millennium Copyright Act (17 U.S.C. § 512) (“DMCA”), the written notice (the “DMCA Notice”) must include (a) your physical or electronic signature; (b) identification of the copyrighted work you believe to have been infringed or, if the claim involves multiple works on the Website, a representative list of such works; (c) identification of the material you believe to be infringing in a sufficiently precise manner to allow us to locate that material; (d) adequate information by which we can contact you (including your name, postal address, telephone number and, if available, e-mail address); (e) a statement that you have a good faith belief that use of the copyrighted material is not authorized by the copyright owner, its agent or the law; (f) a statement that the information in the written notice is accurate; and (g) a statement, under penalty of perjury, that you are authorized to act on behalf of the copyright owner. If you fail to comply with all of the requirements of Section 512(c)(3) of the DMCA, your DMCA Notice may not be effective. Please be aware that if you knowingly materially misrepresent that material or activity on the Website is infringing your copyright, you may be held liable for damages (including costs and attorneys’ fees) under Section 512(f) of the DMCA.</p>

<p>Counter-Notification Procedures. If you believe that material you posted on the Website was removed or access to it was disabled by mistake or misidentification, you may file a counter-notification with us (a “Counter-Notice“) by submitting written notification to our Copyright Agent (identified below). Pursuant to the DMCA, the Counter-Notice must include (a) your physical or electronic signature; (b) an identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or access disabled; (c) adequate information by which we can contact you (including your name, postal address, telephone number and, if available, e-mail address); (d) a statement under penalty of perjury by you that you have a good faith belief that the material identified above was removed or disabled as a result of a mistake or misidentification of the material to be removed or disabled; (e) a statement that you will consent to the jurisdiction of the Federal District Court for the judicial district in which your address is located (or if you reside outside the United States for any judicial district in which the Website may be found) and that you will accept service from the person (or an agent of that person) who provided the Website with the complaint at issue. The DMCA allows us to restore the removed content if the party filing the original DMCA Notice does not file a court action against you within ten business days of receiving the copy of your Counter-Notice. Please be aware that if you knowingly materially misrepresent that material or activity on the Website was removed or disabled by mistake or misidentification, you may be held liable for damages (including costs and attorneys’ fees) under Section 512(f) of the DMCA.</p>

<p>Copyright Agent. Our designated Copyright Agent to receive DMCA Notices and Counter-Notices is Mark Muskardin, who may be contacted by mail at 490 Lake Park Avenue, #16162, Oakland, CA. 94610-9991; by email at mark.muskardin@gmail.com, or by phone at <strong>__</strong><strong>__</strong><strong>__</strong><em>__</em></p>

<p>Repeat Infringers. It is our policy in appropriate circumstances to disable and/or terminate the accounts of users who are repeat infringers.</p>

<p>Reliance on Information Posted</p>

<p>The information presented on or through the Website is made available solely for general information purposes. We do not warrant the accuracy, completeness or usefulness of this information. Any reliance you place on such information is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other visitor to the Website, or by anyone who may be informed of any of its contents.</p>

<p>This Website may include content provided by third parties, including materials provided by other users, bloggers and third-party licensors, syndicators, aggregators and/or reporting services. All statements and/or opinions expressed in these materials, and all articles and responses to questions and other content, other than the content provided by the Company, are solely the opinions and the responsibility of the person or entity providing those materials. These materials do not necessarily reflect the opinion of the Company. We are not responsible, or liable to you or any third party, for the content or accuracy of any materials provided by any third parties.</p>

<p>Changes to the Website</p>

<p>We may update the content on this Website from time to time, but its content is not necessarily complete or up-to-date. Any of the material on the Website may be out of date at any given time, and we are under no obligation to update such material.</p>

<p>Information About You and Your Visits to the Website</p>

<p>All information we collect on this Website is subject to our Privacy Policy. By using the Website, you consent to all actions taken by us with respect to your information in compliance with the Privacy Policy.</p>

<p>Online Purchases, Refunds, and Other Terms and Conditions</p>

<p>The Company may offer one or more membership programs on its Website. The pricing for any such membership program will be clearly stated on the applicable order form. All purchases through the Website are final and will not be refunded except as expressly stated herein.</p>

<p>Refund Policy. Notwithstanding the foregoing, a member may cancel his/her membership in the applicable program for any reason and obtain a full refund of his/her first membership payment if he/she does so by emailing support@hackingbeauty.com (1) within 30 days of making his/her first membership payment in the “List-Grow” program; and (2) within 14 days of making his/her first membership payment in the “Self-Made Man Society” program.</p>

<p>Additional terms and conditions may also apply to specific portions, services or features of the Website. All such additional terms and conditions are hereby incorporated by this reference into these Terms of Use.</p>

<p>Linking to the Website and Social Media Features</p>

<p>You may link to our homepage, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part without our express written consent.</p>

<p>The Website may provide certain social media features that enable you to: (a) link from your own or certain third-party websites to certain content on the Website; (b) send e-mails or other communications with certain content, or links to certain content, on the Website; or (c) cause limited portions of content on the Website to be displayed or appear to be displayed on your own or certain third-party websites. You may use these features solely as they are provided by us, solely with respect to the content they are displayed with, and otherwise in accordance with any additional terms and conditions we provide with respect to such features.</p>

<p>Subject to the foregoing, you must not (a) establish a link from any website that is not owned by you; (b) cause the Website or portions of it to be displayed, or appear to be displayed by, for example, framing, deep linking or in-line linking, on any other site; (c) link to any part of the Website other than the homepage; (d) otherwise take any action with respect to the materials on this Website that is inconsistent with any other provision of these Terms of Use. You agree to cooperate with us in causing any unauthorized framing or linking immediately to cease. We reserve the right to withdraw linking permission without notice.</p>

<p>We may disable all or any social media features and any links at any time without notice in our discretion.</p>

<p>Links from the Website</p>

<p>If the Website contains links to other sites and resources provided by third parties, these links are provided for your convenience only. This includes links contained in advertisements, including banner advertisements and sponsored links. We have no control over the contents of those sites or resources, and accept no responsibility for them or for any loss or damage that may arise from your use of them. If you decide to access any of the third party websites linked to this Website, you do so entirely at your own risk and subject to the terms and conditions of use for such websites.</p>

<p>Geographic Restrictions</p>

<p>The owner of the Website is based in the United States. We provide this Website for use only by persons located in the United States. We make no claims that the Website or any of its content is accessible or appropriate outside of the United States. Access to the Website may not be legal by certain persons or in certain countries. If you access the Website from outside the United States, you do so on your own initiative and are responsible for compliance with local laws.</p>

<p>Disclaimer of Warranties</p>

<p>You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the Website will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.</p>

<p>YOUR USE OF THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.</p>

<p>WITHOUT LIMITING THE FOREGOING, THE COMPANY IS NOT ACTING AS A FINANCIAL OR LEGAL ADVISOR AND DOES NOT GUARANTEE THAT YOU WILL ACHIEVE ANY PARTICULAR RESULT FROM USING THE WEBSITE OR FROM PARTICIPATING IN THE COMPANY’S MEMBERSHIP PROGRAMS LOCATED ON ITS WEBSITES. PLEASE SEE OUR COMPLETE EARNINGS DISCLAIMERS FOR ADDITIONAL DETAILS, FOUND AT HTTP://hackingbeauty.com/EARNINGS-DISCLAIMER/. THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR PARTICULAR PURPOSE.</p>

<p>Limitation on Liability</p>

<p>IN NO EVENT WILL THE COMPANY, ITS AFFILIATES OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, THE MEMBERSHIP PROGRAMS AVAILABLE THROUGH THE WEBSITE, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE.</p>

<p>Indemnification</p>

<p>You agree to defend, indemnify and hold harmless the Company, its affiliates, licensors and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys’ fees) arising out of or relating to your violation of these Terms of Use or your use of the Website, including, but not limited to, your User Contributions, any use of the Website’s content, services and products other than as expressly authorized in these Terms of Use or your use of any information obtained from the Website.</p>

<p>Governing Law and Jurisdiction</p>

<p>All matters relating to the Website and these Terms of Use and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of the State of Texas without giving effect to any choice or conflict of law provision or rule (whether of the State of Texas or any other jurisdiction).</p>

<p>Any legal suit, action or proceeding commenced by you and arising out of, or related to, these Terms of Use or the Website shall be instituted exclusively in the state or federal district courts located in Travis County, Texas. Notwithstanding the foregoing, we retain the right to bring any suit, action or proceeding against you for breach of these Terms of Use in Travis County, Texas or in your county of residence (if in the United States) or any permissible venue (if outside the United States). You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.</p>

<p>Arbitration</p>

<p>At the Company’s sole discretion, it may require you to submit any disputes arising from the use of these Terms of Use or the Website, including disputes arising from or concerning their interpretation, violation, invalidity, non-performance, or termination, to final and binding arbitration under the Rules of Arbitration of the American Arbitration Association applying Texas law.</p>

<p>Waiver and Severability</p>

<p>No waiver by the Company of any term or condition set forth in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of the Company to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision.</p>

<p>If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Use will continue in full force and effect.</p>

<p>Entire Agreement</p>

<p>The Terms of Use and the other documents specifically incorporated herein constitute the sole and entire agreement between you and the Company with respect to the Website and supersede all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to the Website. Neither you nor Company are relying on any representation not contained herein (or in the other documents specifically incorporated herein).</p>

<p>Contacting Us</p>

<p>If you need to contact us, please email us at support@hackingbeauty.com, or send us a letter at: 475 Weldon Avenue, Apt 306, Oakland, CA. 94610.</p>
      </div>
    </section>
  </Layout>
);
